import FontFaceObserver from 'fontfaceobserver';

export const fontObserver = () => {
  const font = new FontFaceObserver('Roboto', {
    weight: 'normal',
    style: 'normal',
  });
  const fontBold = new FontFaceObserver('Roboto', {
    weight: 'bold',
    style: 'normal',
  });
  const fontItalic = new FontFaceObserver('Roboto', {
    weight: 'normal',
    style: 'italic',
  });
  Promise.all([font.load(), fontBold.load(), fontItalic.load()])
    .then(function () {
      document.documentElement.classList.add('fonts-loaded');
    })
    .catch((err) => {
      console.log(err);
    });
};
