import Link from 'next/link';
import {ContentSection} from './section';
import styled from 'styled-components';
import Grid from 'styled-components-grid';

const Wrapper = styled.div`
  background-color: ${({theme}) => theme.colors.black};
  width: 100%;
`;

const StyledGrid = styled(Grid)``;

const StyledGridUnit = styled(Grid.Unit)`
  margin: ${({theme}) => theme.spacing.small} 0;
`;

const SectionItem = styled.div`
  margin: ${({theme}) => theme.spacing.xsmall} 0;
  color: ${({theme}) => theme.colors.white};
  opacity: 0.8;
`;

const SectionTitle = styled(SectionItem)`
  font-weight: bold;
  margin: ${({theme}) => theme.spacing.small} 0;
  opacity: 1;
`;

const SectionLink = styled.a`
  margin: ${({theme}) => theme.spacing.xsmall} 0;
  color: ${({theme}) => theme.colors.white};
  opacity: 0.8;
  display: flex;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Footer = () => (
  <Wrapper>
    <ContentSection>
      <StyledGrid>
        <StyledGridUnit size={{xs: 1, sm: 1, md: 1 / 2}}>
          <SectionTitle>Links</SectionTitle>
          <Link href={'/referenzen'} as={'/referenzen'} passHref>
            <SectionLink>Referenzen</SectionLink>
          </Link>
          <Link href={'/news'} as={'/news'} passHref>
            <SectionLink>Aktuelles</SectionLink>
          </Link>
          <Link href={'/kontakt'} as={'/kontakt'} passHref>
            <SectionLink>Kontakt</SectionLink>
          </Link>
          <Link href={'/impressum'} as={'/impressum'} passHref>
            <SectionLink>Impressum</SectionLink>
          </Link>
          <Link href={'/datenschutz'} as={'/datenschutz'} passHref>
            <SectionLink>Datenschutz</SectionLink>
          </Link>
        </StyledGridUnit>
        <StyledGridUnit size={{xs: 1, sm: 1, md: 1 / 2}}>
          <SectionTitle>Kontakt</SectionTitle>
          <SectionItem>Pernikl GmbH</SectionItem>
          <SectionItem>Rohnen 1080</SectionItem>
          <SectionItem>6861 Alberschwende</SectionItem>
          <SectionItem>Tel: +43(5579)20085-0</SectionItem>
        </StyledGridUnit>
        <StyledGridUnit>
          <SectionItem>© {new Date().getFullYear()} Pernikl GmbH - Akustik u. Schallschutztechnik</SectionItem>
        </StyledGridUnit>
      </StyledGrid>
    </ContentSection>
  </Wrapper>
);

export default Footer;
