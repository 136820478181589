import {NextSeo, ProductJsonLd, BlogJsonLd} from 'next-seo';

const SeoHeader = ({title, description, url, image}) => (
  <NextSeo
    title={`${title} | Pernikl GmbH - Akustik u. Schallschutztechnik`}
    description={description}
    canonical={`https://pernikl.com${url}`}
    openGraph={{
      url: `https://pernikl.com${url}`,
      title: title,
      description: description,
      images: [
        {
          url: image,
        },
      ],
      site_name: 'Pernikl',
    }}
  />
);

export const ProductSeoHeader = ({name, images, description, brand}) => (
  <ProductJsonLd
    productName={name}
    images={images}
    description={description}
    brand={brand}
    mpn={name}
    aggregateRating={{
      ratingValue: '4.9',
      reviewCount: '100',
    }}
  />
);

export const BlogSeoHeader = ({url, title, images, description}) => (
  <BlogJsonLd url={url} title={title} images={images} description={description} />
);

export default SeoHeader;
