import styled from 'styled-components';

const Section = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-left: ${({theme}) => theme.spacing.default};
  padding-right: ${({theme}) => theme.spacing.default};
  padding-top: ${({theme}) => theme.spacing.large};
  padding-bottom: ${({theme}) => theme.spacing.large};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ContentSection = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding-left: ${({theme}) => theme.spacing.default};
  padding-right: ${({theme}) => theme.spacing.default};
  padding-top: ${({theme}) => theme.spacing.large};
  padding-bottom: ${({theme}) => theme.spacing.large};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default Section;
