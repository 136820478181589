import styled from 'styled-components';
import ReactPlayer from 'react-player';
import {FiPlay} from 'react-icons/fi';

export const H2 = styled.h2`
  font-size: ${({theme}) => theme.fontSize.large};
  font-weight: bold;
`;

export const H3 = styled.h3`
  font-size: ${({theme}) => theme.fontSize.medium};
  font-weight: bold;
`;

export const P = styled.p`
  font-size: ${({theme}) => theme.fontSize.default};
`;

export const Blockquote = styled.blockquote`
  font-size: ${({theme}) => theme.fontSize.default};
`;

export const Img = styled.img`
  max-height: 200px;
  max-width: 100%;
`;

const StlyedReactPlayer = styled(ReactPlayer)`
  & {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
    background: ${({theme}) => theme.colors.lightgrayOverlay};
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
`;

const StyledFiPlay = styled(FiPlay)`
  height: 100px;
  width: 100px;
  color: ${({theme}) => theme.colors.green};
  fill: ${({theme}) => theme.colors.green};
`;

export const Video = ({url}) => {
  if (url) {
    return (
      <VideoWrapper>
        <StlyedReactPlayer pip light playing playIcon={<StyledFiPlay />} url={url} playsinline />
      </VideoWrapper>
    );
  }
};
