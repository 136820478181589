import styled from 'styled-components';

const Page = styled.div`
  margin-top: ${({theme}) => theme.menuHeight};
  width: 100vw;
  display: flex;
  flex-direction: column;
`;

export default Page;
