import styled from 'styled-components';
import {FiChevronsDown} from 'react-icons/fi';
import Section from './section';
import ProgressiveImage from 'react-progressive-image';
import {media} from '../lib/theme';
import {LazyLoadComponent} from 'react-lazy-load-image-component';
import {setCookie, parseCookies} from 'nookies';
import {useState, useEffect} from 'react';
import Link from 'next/link';

export const COOKIE_USER_ACCEPTED = 'acceptCookie';
export const COOKIE_USER_ACCEPTED_VALUE = 'true';

const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  word-break: break-word;
  padding-left: ${({theme}) => theme.spacing.default};
  padding-right: ${({theme}) => theme.spacing.default};
`;

const Title = styled.h1`
  color: ${({theme}) => theme.colors.white};
  margin: 0;
  padding: 0;
  font-size: ${({theme}) => theme.fontSize.large};
  ${media.sm`
    font-size: ${({theme}) => theme.fontSize.big};
  `}
  ${media.md`
    font-size: ${({theme}) => theme.fontSize.xxl};
  `}
  text-align: left;
  max-width: 750px;
`;

const Description = styled.p`
  max-width: 450px;
  color: ${({theme}) => theme.colors.white};
  margin: 0;
  padding: 0;
  text-align: left;
  margin-top: ${({theme}) => theme.spacing.default};
  font-size: ${({theme}) => theme.fontSize.default};
`;

const ScrollDownIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: ${({theme}) => theme.spacing.default};
`;

const ScrollDownIcon = styled.div`
  animation: shake-animation 2s ease-in-out infinite;
  color: ${({theme}) => theme.colors.white};
  height: 35px;
  width: 35px;
  & > svg {
    stroke-width: 0.75;
    height: 100%;
    width: 100%;
  }
`;

const FullscreenImageWrapper = styled.div`
  background-color: ${({theme}) => theme.colors.white};
  width: 100%;
  height: ${({theme}) => `calc(100vh - ${theme.menuHeight})`};
  position: relative;
  display: flex;
  flex-direction: column;
`;

const BackgroundImage = styled.div`
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  filter: ${({blur}) => (blur ? `blur(100px)` : `blur(0px)`)};
  transition: 200ms ease-in-out;
  background-image: ${({image}) => `url(${image})`};
  position: absolute;
`;

const FullscreenImageOverlay = styled.div`
  background: ${({theme}) => theme.colors.blackOverlay};
  padding-bottom: 75px;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
`;

export const CookieWrapper = styled.section`
  position: fixed;
  bottom: 0;
  background: ${({theme}) => theme.colors.white};
  left: 0;
  right: 0;
`;

export const CookieInnerWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-left: ${({theme}) => theme.spacing.default};
  padding-right: ${({theme}) => theme.spacing.default};
  padding-top: ${({theme}) => theme.spacing.default};
  padding-bottom: ${({theme}) => theme.spacing.default};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.sm`
    flex-direction: row;
  `}
`;

const CookieText = styled.div`
  flex: 1;
  font-size: ${({theme}) => theme.fontSize.small};
  margin-right: ${({theme}) => theme.spacing.default};
  & a {
    font-size: ${({theme}) => theme.fontSize.small};
    text-transform: none;
    text-decoration: none;
    font-weight: bold;
    color: ${({theme}) => theme.colors.green};
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
`;

const CookieButton = styled.button`
  margin: ${({theme}) => theme.spacing.default} 0 0 0;
  padding: ${({theme}) => theme.spacing.xsmall} ${({theme}) => theme.spacing.default};
  border: 2px solid ${({theme}) => theme.colors.black};
  color: ${({theme}) => theme.colors.black};
  background-color: ${({theme}) => theme.colors.white};
  text-decoration: none;
  font-weight: 600;
  text-transform: uppercase;
  font-size: ${({theme}) => theme.fontSize.small};
  transition: 250ms ease-in-out;
  width: 100%;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  ${media.sm`
    margin: 0;
    width: unset;
  `}
  &:hover {
    border-color: ${({theme}) => theme.colors.black};
    color: ${({theme}) => theme.colors.white};
    background-color: ${({theme}) => theme.colors.black};
  }
`;

const FullscreenImage = ({placeholderImage, largeImage, children}) => {
  return (
    <FullscreenImageWrapper>
      <LazyLoadComponent>
        <ProgressiveImage src={largeImage} placeholder={placeholderImage}>
          {(src, loading) => <BackgroundImage blur={loading} image={src} />}
        </ProgressiveImage>
      </LazyLoadComponent>
      <FullscreenImageOverlay>{children}</FullscreenImageOverlay>
    </FullscreenImageWrapper>
  );
};

const Header = ({title, description, largeImage, placeholderImage}) => {
  const [accepted, setAccepted] = useState(null);
  const accept = () => {
    setCookie(null, COOKIE_USER_ACCEPTED, COOKIE_USER_ACCEPTED_VALUE, {
      maxAge: 365 * 24 * 60 * 60,
      path: '/',
    });
    setAccepted(true);
  };
  useEffect(() => {
    const cookies = parseCookies();
    const cookieAccepted = cookies[COOKIE_USER_ACCEPTED] === COOKIE_USER_ACCEPTED_VALUE;
    setAccepted(cookieAccepted);
  }, []);
  return (
    <>
      <FullscreenImage largeImage={largeImage} placeholderImage={placeholderImage}>
        <Section>
          <ContentWrapper>
            <Title>{title}</Title>
            {description && <Description>{description}</Description>}
          </ContentWrapper>
          <ScrollDownIconWrapper>
            <ScrollDownIcon>
              <FiChevronsDown />
            </ScrollDownIcon>
          </ScrollDownIconWrapper>
        </Section>
      </FullscreenImage>
      {!(accepted || accepted === null) && (
        <CookieWrapper>
          <CookieInnerWrapper>
            <CookieText>
              Diese Seite verwendet Cookies. Wenn Sie weiterhin auf der Website surfen, stimmen Sie der Verwendung von
              Cookies zu.{' '}
              <Link passHref href={'/datenschutz'} passHref>
                <a>Datenschutz</a>
              </Link>
            </CookieText>
            <CookieButton onClick={() => accept()}>Ok</CookieButton>
          </CookieInnerWrapper>
        </CookieWrapper>
      )}
    </>
  );
};

export default Header;
